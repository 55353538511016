import React from "react"
import styled from "styled-components"
import Schemas from "../../images/schemas.png"
import Formik from "./form";
import PlusComponent from "../../ui/plusComponent";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media (max-width: 991px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 576px) {
    align-items: flex-start;
    margin-left: 15px;
  }
  
`;

const Container = styled.div`
  line-height: 0;
  position: relative;
  width: 100%;
  overflow: hidden;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #0B0A0A -51.49%, rgba(11, 10, 10, 0) 18.73%, #0B0A0A 88.95%);
    opacity: 0.8;
  }

  @media (max-width: 768px) {
    height: 457px;
  }
  @media (max-width: 576px) {

    height: 343px;
  }
  @media (max-width: 427px) {

    height: 230px;
  }
  @media (max-width: 323px) {

    height: 230px;
  }
`;

const FormContainer = styled.div`
  margin-top: -30px;
  
  @media (max-width: 991px) {
    margin-top: 42px;
  }
  
`;

const ImgContainer = styled.img`
  transform: rotate(-5deg);
  position: absolute;
  margin-top: 100px;
  @media (max-width: 768px) {
    width: 422.71px;
    height: 725.23px;
    margin-top: 10px;
  }
  @media (max-width: 576px) {
    width: 322.71px;
    height: 505.23px;
    margin-top: 10px;
  }
  @media (max-width: 427px) {
    width: 370px;
    height: 365.23px;
  }
  
`;
const FormBlock = () => {
    return (
        <>
            <PlusComponent text='ОБСУДИТЬ ПРОЕКТ'/>
            <Wrapper>
                <Container>
                    <ImgContainer src={Schemas} alt="Schemas"/>
                </Container>
                <FormContainer>
                    <Formik/>
                </FormContainer>
            </Wrapper>
        </>
    )
}

export default FormBlock
