import React from 'react';
import markerIcon from "../../images/marker.svg"
import styled from 'styled-components'
import mapIB from "../../images/map.jpg"

const MapboxContainer = styled.div`
  width: 100%;
  height: 760px;
  margin: 50px 0px;
  background-image: url(${mapIB});
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

const MapBox = () => {
    return (
        <MapboxContainer>
            <img style={{cursor: "pointer"}} onKeyPress={() => {
                window.open('https://yandex.ru/maps/213/moscow/house/ulitsa_barklaya_6s5/Z04YcwdoS0cOQFtvfXtyeH5mYQ==/?ll=37.546967%2C55.742098&z=14.32', '_blank').focus();
            }} onClick={() => {
                window.open('https://yandex.ru/maps/213/moscow/house/ulitsa_barklaya_6s5/Z04YcwdoS0cOQFtvfXtyeH5mYQ==/?ll=37.546967%2C55.742098&z=14.32', '_blank').focus();
            }} src={markerIcon} alt={'marker'}/>
        </MapboxContainer>
    )
}

export default MapBox;
