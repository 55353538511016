import React, {useState} from "react";
import styled from "styled-components";
import {graphql} from "gatsby";
import Seo from "../components/seo";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import ActionCards from "../components/ActionCards/ActionCards";
import Header from "../components/Header/header";
import Footer from "../components/Footer/footer";
import MapBox from "../components/MapBox";
import {ContainerL, ContainerMap} from "../ui/containers";
import FormBlock from "../components/Form";
import Modal from "../ui/modal";
import "../components/layout.css";
import CircleButton from "../ui/button";

const HeadingText = styled.div`
  font-weight: 200;
  font-size: 72px;
  line-height: 96px;
  text-transform: uppercase;
  color: #FFFFFF;
  @media (max-width: 600px) {
    margin-top: -60px;
    font-weight: 200;
    font-size: 48px;
    line-height: 58px;
    text-transform: uppercase;
    color: #FFFFFF;
  }
`;


const ActionBlock = styled.div`
  margin: 90px 0 0 ${props => props.marginLeft}px;
  @media (max-width: 600px) {
    margin: 60px 0 0 ${props => props.marginLeft}px;
  }
`;

const Contacts = ({data}) => {
    const screens = useBreakpoint();
    const [openModal, setOpenModal] = useState(false);
    const contactsData = data.allStrapiContacts.nodes[0];

    const toggleOpenModal = () => {
        if (!openModal) document.body.style.overflow = "hidden";
        else document.body.style.overflow = "auto";
        setOpenModal(!openModal);
    };
    return (
        <>
            <Seo title={contactsData.seo_title}
                 description={contactsData.seo_description}
                 keywords={contactsData.seo_keywords.split(',')}
            />

                <>
                    <Header/>
                    <Modal openModal={openModal} toggleOpenModal={toggleOpenModal}/>
                    <ContainerL style={{margin: "80px auto"}}>
                        <HeadingText>КОНТАКТЫ</HeadingText>
                        <ActionBlock marginLeft={!screens.xl ? 30 : 330}>
                            <ActionCards contacts={contactsData}/>
                            <CircleButton
                                text={"Перезвоните мне"}
                                toggleOpenModal={toggleOpenModal}
                            />
                        </ActionBlock>
                    </ContainerL>

                    <ContainerMap style={{margin: "100px auto"}}>
                        <MapBox/>
                    </ContainerMap>

                    <ContainerL style={{margin: "120px auto"}}>
                        <FormBlock/>
                    </ContainerL>

                    <Footer toggleOpenModal={toggleOpenModal}/>
                </>
        </>
    );
};

export default Contacts;

export const query = graphql`
    query ContactsQuery {
        allStrapiContacts {
            nodes {
                seo_description
                seo_keywords
                seo_title
                email
                adress
                phone
                schedule
                instagram
                youtube
                facebook
                telegram
            }
        }
    }

`;
