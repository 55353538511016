import { Col, Row } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React from 'react';
import styled from 'styled-components'
import Plus from './Plus'
import Instagram from "../../images/Instagram.svg"
import Telegram from "../../images/Telegram.svg"
import Facebook from "../../images/Facebook.svg"
import YouTube from "../../images/YouTube.svg"


const Card = styled.div`
    height: 200px;
    width: 100%;
    max-width: 250px;
`;

const GreyHead = styled.div`
    margin: 20px 0px 35px 10px;
    color: #373737;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 18px;
`

const CardContent = styled.div`
    color: white;
    margin-left: 10px;
    font-size:20px;
`

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 23px;
`

const Icon = styled.img`
  height: 28px;
  margin-right: 45px;
`



const getSpan = br => {
    if(br.xl) return 8;
    if(br.lg || br.md || br.sm) return 12;
    if(br.xs) return 16;
}

const ActionCards = ({contacts}) => {
    const screens = useBreakpoint();
    let span = getSpan(screens);

    const DATA = [
        {
            title: "Адресс",
            description: contacts.adress
        },
        {
            title: "Телефон",
            description: contacts.phone
        },
        {
            title: "E-Mail",
            description: contacts.email
        },
        {
            title: "ГРАФИК РАБОТЫ",
            description: contacts.schedule
        },
        {
            title: "Социальные сети",
            description:
              <Icons>
                  <a href={contacts.instagram}><Icon src={Instagram} alt="Icon instagram" /></a>
                  <a href={contacts.facebook}><Icon src={Facebook} alt="Icon facebook" /></a>
                  <a href={contacts.telegram}><Icon src={Telegram} alt="Icon telegram" /></a>
                  <a href={contacts.youtube}><Icon style={{ marginRight: "0" }} src={YouTube} alt="Icon youtube" /></a>
              </Icons>
        }
    ]

    return (
        <Row style={{width: "100%"}} >
            {
                DATA.map(card => <Col key={card.title} span={span} style={{ marginTop: span === 16 ? "40px" : "0" }}>
                    <Card>
                        <Plus />
                        <GreyHead>
                            {card.title}
                        </GreyHead>
                        <CardContent>
                            {card.description}
                        </CardContent>
                    </Card>
                </Col>)
            }
        </Row>
    )
}

export default ActionCards;
